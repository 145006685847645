<template>
  <v-btn icon @click="downloadFile(sceneId)" :disabled="loading">
    <v-icon>mdi-download</v-icon>
  </v-btn>
</template>

<script>
import axios from "axios";

const axiosInstance = axios.create()

export default {
  name: "ResultsOverview",
  props: ["sceneId"],
  data: () => ({
    loading: false
  }),
  methods: {
    async downloadFile(){
      this.loading = true
      const url = `https://s2-tiler.api.eomap.com/download/${this.sceneId}?token=a9a64882-79bb-4716-a441-922cdd0b5f2b`
      const response = await axiosInstance.get(url)

      window.location = response.data.rgb
      this.loading = false
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>mdi-layers</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title
      >
        {{ formatDateTime(request.created_at) }}
      </v-list-item-title>

      <v-list-item-subtitle>
        {{ $t("resultItem.dateStart") }}: {{ request.datetime.split("/")[0] }}
      </v-list-item-subtitle>

      <v-list-item-subtitle>
        {{ $t("resultItem.dateEnd") }}: {{ request.datetime.split("/")[1] }}
      </v-list-item-subtitle>

      <v-list-item-subtitle>
        {{ $t("resultItem.tiles") }}:
        <span v-for="(tile_id, index) in tile_ids" :key="index"> {{ tile_id }}, </span>
      </v-list-item-subtitle>

      <v-list-item-subtitle>
        {{ $t("resultItem.state") }}: {{ $t("resultItem.states." + status) }}
      </v-list-item-subtitle>

      <div style="min-height: 4px;">
        <v-progress-linear
            v-model="progressValue"
            :active="show"
            :indeterminate="query"
            :query="true"
        ></v-progress-linear>
        <v-list-item-subtitle v-if="show" >{{ progressValue.toFixed(0) }}%</v-list-item-subtitle>
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon @click.stop="showRequestAoi(request._id)">
        <v-icon>mdi-magnify-expand</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-action class="ml-1">
      <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon @click.stop="deleteRequest(request.id)"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span v-if="(status == 'processing') || (status == 'queued') || status == 'searching'" >
              {{ $t("resultItem.delete_tip") }}
            </span>
            <span v-else> {{ $t("resultItem.delete_tip2") }} </span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import axios from "axios";


export default {
  name: "ResultItem",
  props: ["request"],

  data() {
    return {
      selectedResultId: null,
      progressValue: 0,
      status: this.request.status,
      tile_ids: this.request.tile_ids,
      query: false,
      show: false,
      pollInterval: null,
    }
  },

  methods: {
    showRequestAoi() {
      const geojson = JSON.parse(JSON.stringify(this.request.aoi));
      const geojsonLeaflet = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: geojson
          }
        ]
      }
      this.$leaflet.showSensorTileOverlay();
      this.$leaflet.addGeojsonToResultItems(geojsonLeaflet);
      this.$leaflet.zoomToResultItems();

    },
    formatDateTime(dt) {
      return `${dt.split("T")[0]} ${(dt.split("T")[1]).split(".")[0]}`;
    },
    async fetchProgress() {
      const response = await axios.get(`/requests/${this.request.id}`);
      const data = await response.data
      if(data.scenes_found > 0) {
        this.progressValue = 100 * ((data.scenes_processed + data.scenes_failed) / data.scenes_found);
      }else{
        this.progressValue = 0
      }
      this.status = data.status;
      this.tile_ids = data.tile_ids;
      this.query = false;
      if ((this.progressValue === 100 & this.status === "done")) {
        this.$emit("statusDone", this.tile_ids)
        this.query = false;
        this.show = false;
        clearInterval(this.pollInterval)
      }
    },
    async deleteRequest(){
      const response = await axios.delete(`/requests/${this.request.id}`)
      const data = response.data
      if(data.status == "deleted") {
        this.$emit("deleteItem", this.request.id)
      }
    }
  },

  mounted() {
    if (this.status !== "done") {
      // long polling
      this.query = true;
      this.show = true;
      this.pollInterval = setInterval(this.fetchProgress, 4000);
      setTimeout(() => {
        clearInterval(this.pollInterval);
      }, 1800000);
    }
  },

  beforeDestroy() {
    clearInterval(this.pollInterval)
  },

}
</script>

<style scoped>
</style>

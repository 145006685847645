import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
    defaultSet: "mdi"
  },
    theme: {
        themes: {
            light: {
                primary: "#2c4186",
                accent: colors.orange
            },
            dark: {
                primary: "#2c4186"
            }
        }
    }
});
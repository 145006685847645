<template>
  <div id="map">
    <map-action-buttons class="map-buttons"/>
  </div>
</template>


<script>
import MapActionButtons from "./MapActionButtons";

export default {
  name: "MainMap",
  components: {
    MapActionButtons
  },
  mounted() {
    this.$leaflet.initMap("map", true);
  },
  activated() {
    if (this.map) {
      this.map.resize();
    }
  },
}
</script>

<style scoped>
.map-buttons {
  z-index: 1000;
}

#map {
  height: 100%;
  width: 100%;
  z-index: 1;
}
</style>

<style> /* Global */
/* Disable all leaflet-draw tooltips */
.leaflet-draw-tooltip {
  display: none;
}
</style>

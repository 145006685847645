<template>
  <v-navigation-drawer
      :width="400"
      v-model="showMenu"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
  >
    <v-btn-toggle
            v-model="chosenMenu"
            tile
            group
            color="primary"
        >
          <v-btn
              value="requests"
              to="/requests"
              class="ma-0"
              block
          >
            {{ $t("baseMenu.request") }}
          </v-btn>
          <v-btn
              value="results"
              to="/results"
              class="ma-0"
              block
          >
            {{ $t("baseMenu.results") }}
          </v-btn>
        </v-btn-toggle>
        <v-divider/>
    <slot></slot>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "BaseMenu",
  data() {
    return {
      chosenMenu: this.initialMenu,
    }
  },
  props: [
    "initialMenu"
  ],
  computed: {
    showMenu: {
      get() {
        return this.$store.state.app.showMenu;
      },
      set(val) {
        this.$store.commit('app/SHOW_MENU', val);
      }
    }
  },
}
</script>

<style scoped>
</style>

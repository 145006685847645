<template>
  <v-card>
    <v-card-title class="primary white--text mb-3 text-subtitle-2 justify-center">
      <v-btn icon @click="previousScene" :disabled="disablePreviousButton">
        <v-icon class="white--text">mdi-chevron-left</v-icon>
      </v-btn>
      {{ selectedScene.scene_id.replace('MSIL2A', 'MSIL1C') }}
      <v-btn icon @click="nextScene" :disabled="disableNextButton">
        <v-icon class="white--text">mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
          fab
          small
          icon
          style="position: absolute; right: 10px"
          @click="closeDialog"
      >
        <v-icon class="white--text">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row no-gutters>
        <v-col md="9">
          <div id="detail-map"></div>
        </v-col>
        <v-col md="3">
          <v-slider
              max="10"
              min="1"
              v-model="imageStretch"
              label="Image stretch"
              hide-details
              class="ma-4"
          ></v-slider>
          <v-simple-table dense style="margin-bottom: 20px;">
            <tbody>
            <tr>
              <td>{{ $t("resultsOverview.rank") }}</td>
              <td>{{ selectedScene.rank }}</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblTotalScore") }}</td>
              <td>{{ selectedScene.total_score }}%</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblTile") }}</td>
              <td>{{ selectedScene.scene_id.split("_")[5].substring(1) }}</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblDate") }}</td>
              <td>{{ selectedScene.datetime.split("T")[0] }}</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblTime") }}</td>
              <td>{{ selectedScene.datetime.split("T")[1].split(".")[0] }}</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblSunElevation") }}</td>
              <td>{{ (90 - selectedScene.sun_zenith_angle).toFixed(2) }}°</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblCover") }}</td>
              <td>{{ (selectedScene.coverage * 100).toFixed(0) }}%</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblCloudPixels") }}</td>
              <td>{{ (selectedScene.cloud_pixels * 100).toFixed(1) }}%</td>
            </tr>
            <tr>
              <td>{{ $t("singleResultDetails.lblWaterPixels") }}</td>
              <td>{{ (selectedScene.water_pixels * 100).toFixed(1) }}%</td>
            </tr>
            </tbody>
          </v-simple-table>
          <div class="mx-4">
            <div style="width: 100%">
              <span class="subtitle-1">Scene</span>
            </div>
            <div style="width: 100%" class="ma-1">
              <v-btn :href="downloadUrl" tile>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <div style="width: 100%" class="mt-5">
              <span class="subtitle-1">Feasibility AOI</span>
            </div>
            <div style="width: 100%" class="ma-1">
              <v-btn class="mr-2 my-1" @click="$leafletDetail.drawPolygon()">
                <v-icon>mdi-shape-polygon-plus</v-icon>
              </v-btn>
              <v-btn class="mr-2 my-1" @click="$leafletDetail.clearDraw()">
                <v-icon color="grey darken-3">mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn class="my-1" @click="downloadAoi">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>


</template>

<script>
import axios from "axios";
import {exportJSONToFile} from "@/services/export.service";

const axiosInstance = axios.create()

export default {
  name: "SingleResultDetails",
  props: [
    "scene",
    "sceneList"
  ],

  data() {
    return {
      selectedScene: this.scene,
      downloadUrl: "",
      imageStretch: 1
    }
  },

  computed: {
    disableNextButton() {
      if (this.sceneList) {
        return (
            this.sceneList.indexOf(this.selectedScene) ===
            this.sceneList.length - 1
        );
      }
      return false;
    },
    disablePreviousButton() {
      if (this.sceneList) {
        return this.sceneList.indexOf(this.selectedScene) === 0;
      }
      return false;
    },

  },

  methods: {
    displayScene() {
      this.$leafletDetail.removeSceneTileLayer()
      this.$leafletDetail.addSceneTileLayer(this.selectedScene.scene_id, this.imageStretch)
    },
    async showRequestAoi() {
      const response = await axios.get(
          `/requests/${this.scene.request_id}`
      );
      const data = response.data;
      const geojson = JSON.parse(JSON.stringify(data.aoi));
      const geojsonLeaflet = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: geojson
          }
        ]
      }
      this.$leafletDetail.addGeojsonToResultItems(geojsonLeaflet);
      this.$leafletDetail.zoomToResultItems();
    },

    previousScene() {
      const selection = this.sceneList;
      const selected = this.selectedScene;
      const selectedIndex = selection.indexOf(selected);
      if (selectedIndex > 0) {
        this.selectedScene = selection[selectedIndex - 1];
      } else if (selectedIndex === 0) {
        this.selectedScene = selection[selection.length - 1];
      }
      this.fetchDownloadUrl()
    },
    nextScene() {
      const selection = this.sceneList;
      const selected = this.selectedScene;
      const selectedIndex = selection.indexOf(selected);
      if (selectedIndex < selection.length - 1) {
        this.selectedScene = selection[selectedIndex + 1];
      }
      this.fetchDownloadUrl()
    },
    closeDialog() {
      this.$emit('close')
    },
    async fetchDownloadUrl() {
      const url = `https://s2-tiler.api.eomap.com/download/${this.selectedScene.scene_id}?token=a9a64882-79bb-4716-a441-922cdd0b5f2b`
      const response = await axiosInstance.get(url)
      this.downloadUrl = response.data.rgb
    },

    downloadAoi() {
      const geojson = this.$leafletDetail.getSelectedAoiAsGeojson()
      exportJSONToFile(geojson, "swift-aoi.geojson")
    }
  },

  mounted() {
    this.$leafletDetail.initMap("detail-map")
    this.$leafletDetail.initDraw()
    this.showRequestAoi()
    this.displayScene()
  },

  created() {
    this.fetchDownloadUrl()
  },

  watch: {
    selectedScene: {
      handler() {
        this.displayScene()
      }
    },
    imageStretch: {
      handler() {
        this.displayScene()
      }
    }
  }
}

</script>

<style scoped>
#detail-map {
  height: 75vh;
  width: 100%;
  z-index: 1;
}

</style>

import { render, staticRenderFns } from "./DownloadBtn.vue?vue&type=template&id=157f0dc0&scoped=true&"
import script from "./DownloadBtn.vue?vue&type=script&lang=js&"
export * from "./DownloadBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157f0dc0",
  null
  
)

export default component.exports
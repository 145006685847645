import L from "leaflet";
import "leaflet-draw";
import axios from "axios";
import Vue from "vue";

class Leaflet {

    constructor() {
        this.map = null;
        this.mapCenter = [40.86, 34.56];
        this.mapZoom = 2;
        this.drawTools = null;
        this.drawnItemsGroup = null;
        this.resultItemsGroup = null;
        this.editHandler = null;
        this.editToolbar = null;
        this.sensorTilesOverlay = null;
        this.selectedFeature = null;
        this.sceneTileLayer = null;

        // left to right
        this.bounds = L.latLngBounds(
            L.latLng(-90, -Infinity),

            L.latLng(90, Infinity)
        );
        // add observables, can be used in computed props etc.
        this.vueObs = Vue.observable({
            hasFeatures: false,
            numberOfFeatures: 0,
            hasSelectedFeature: false,
        });
    }

    initMap(id, isMainMap = false) {
        const mapboxUrl = "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZW9tYXAiLCJhIjoiY2tkZnhvaDJiMjAzeTM2bWh3cHlpN3o0NyJ9.f24MRfc5um4GmKiQC5bCoQ";
        const grayscale = L.tileLayer(mapboxUrl, {
            id: "mapbox/light-v9",
            tileSize: 512,
            zoomOffset: -1,
            minZoom: 2,
        });
        this.map = L.map(id, {
            center: this.mapCenter,
            zoom: this.mapZoom,
            layers: [grayscale],
        });
        this.map.zoomControl.remove();
        L.control.zoom({ position: 'topright' }).addTo(this.map);

        this.map.setMaxBounds(this.bounds);
        if (isMainMap) {
            this.loadSensorTilesOverlay("/static/sentinel-2.geojson");
        }
        this.initDraw();
        this.initEvents();
        return this.map;
    }

    initDraw() {
        this.drawnItemsGroup = L.featureGroup()
            // Make features selectable
            .on("click", (e) => {
                if (this.selectedFeature) {
                    // Change color if selected
                    this.selectedFeature.setStyle({color: "#3388ff"})
                    if (this.selectedFeature._leaflet_id === e.layer._leaflet_id) {
                        // deselect feature
                        this.selectedFeature = null;
                        this.vueObs.hasSelectedFeature = false;
                        return;
                    }
                }
                this.vueObs.hasSelectedFeature = true;
                this.selectedFeature = e.layer;
                this.selectedFeature.setStyle({color: "orange"});
            });
        this.map.addLayer(this.drawnItemsGroup);

        this.resultItemsGroup = L.featureGroup();

        this.map.addLayer(this.resultItemsGroup);
        // add draw tools
        this.drawTools = {
            polygon: new L.Draw.Polygon(this.map, {allowIntersection: false}),
            rectangle: new L.Draw.Rectangle(this.map),
        };
        // add edit functions
        this.editToolbar = new L.EditToolbar({
            featureGroup: this.drawnItemsGroup
        });
        this.editHandler = this.editToolbar.getModeHandlers()[0].handler;
        this.editHandler._map = this.map;
        // activates hooks by default (and the tooltip...)
        this.editHandler.addHooks();
    }

    addSceneTileLayer(scene, imageStretch) {
        if (this.sceneTileLayer) {
            this.map.removeLayer(this.sceneTileLayer);
        }
        let url = `https://s2-tiler.api.eomap.com/tile/${scene}/{z}/{x}/{y}?token=a9a64882-79bb-4716-a441-922cdd0b5f2b`
        if (imageStretch > 1){
            url += `&stretch=${imageStretch}`
        }
        this.sceneTileLayer = L.tileLayer(url);
        this.map.addLayer(this.sceneTileLayer);
    }

    removeSceneTileLayer() {
        if (this.sceneTileLayer) {
            this.map.removeLayer(this.sceneTileLayer);
        }
    }

    initEvents() {
        this.map.on("drag", () => {
            this.map.panInsideBounds(this.bounds, {animate: true});
        });
        this.map.on("draw:drawstart", () => {
            this.drawnItemsGroup.clearLayers();
        });
        this.map.on("draw:created", (e) => {
            const layer = e.layer;
            this.drawnItemsGroup.addLayer(layer);
            this._updateVueObs();
        });
        this.map.on("draw:edited", () => {
        });
    }

    drawRectangle() {
        this.drawTools.rectangle.enable();
    }

    drawPolygon() {
        this.drawTools.polygon.enable();
    }

    editDrawnItems() {
        // this.editHandler.addHooks();
        this.editHandler.enable();
    }

    disableEditDrawnItems() {
        // does not work (??)
        // this.editHandler.removeHooks();
        this.editHandler.disable();
    }

    saveEditedDrawnItems() {
        this.editHandler.save();
        this.disableEditDrawnItems();
    }

    cancelEditDrawnItems() {
        this.editHandler.revertLayers();
        this.disableEditDrawnItems();
    }

    clearDraw() {
        this.drawnItemsGroup.clearLayers();
        this.vueObs.hasFeatures = false;
        this.selectedFeature = null;
    }

    getSelectedAoiAsGeojson() {
        if (!this.vueObs.hasFeatures) {
            return null;
        }
        if (this.vueObs.numberOfFeatures > 1) {
            if (!this.vueObs.hasSelectedFeature) {
                return null;
            } else {
                return this.selectedFeature.toGeoJSON();
            }
        }
        const aoi = this.drawnItemsGroup.toGeoJSON().features[0];
        // check for 3dim coordinates
        aoi["geometry"]["coordinates"][0].forEach(function (coordinate, index) {
            if (coordinate.length > 2) {
                aoi["geometry"]["coordinates"][0][index] = coordinate.slice(0,2);
            }
        })
        return aoi
    }

    addGeojsonToDrawnItems(geojson) {
        this.clearDraw();
        this._addGeojsonToGroup(geojson, this.drawnItemsGroup);
        this._updateVueObs();
    }

    addGeojsonToResultItems(geojson) {
        this.resultItemsGroup.clearLayers();
        this._addGeojsonToGroup(geojson, this.resultItemsGroup, {color: "orange", fillOpacity: 0});
    }

    addLayersToDrawnItems(layers) {
        this.clearDraw();
        Object.keys(layers).forEach((key) => {
            const layer = layers[key];
            console.log(layer)
            // Multipolygon => Polygons
            if (layer.feature.geometry.type === "MultiPolygon") {
                layer.feature.geometry.coordinates.forEach((coordinates) => {
                    const polygon = {type: "Polygon", coordinates: coordinates};
                    const layer = L.GeoJSON.geometryToLayer(polygon);
                    this.drawnItemsGroup.addLayer(layer);
                });
            } else {
                this.drawnItemsGroup.addLayer(layer);
            }
            this._updateVueObs();
        });

    }

    zoomToDrawnItems() {
        this._zoomToGroup(this.drawnItemsGroup);
    }

    zoomToResultItems() {
        this._zoomToGroup(this.resultItemsGroup);
    }

    async loadSensorTilesOverlay(filePath) {
        const response = await axios({method: 'get', baseURL: filePath});
        const data = await response.data;

        this.sensorTilesOverlay = new L.GeoJSON(data, {
            style: () => {
                return {
                    color: "#3388ff",
                    fill: true,
                    fillColor: null,
                    fillOpacity: 0.1,
                    opacity: 0.5,
                    stroke: true,
                    weight: 2,
                }
            },
            onEachFeature: (feature, layer) => {
                layer.bindTooltip(feature.properties.Name);
            }
        });
    }

    showSensorTileOverlay() {
        this.map.addLayer(this.sensorTilesOverlay);
    }

    hideSensorTileOverlay() {
        this.map.removeLayer(this.sensorTilesOverlay);
    }


    _addGeojsonToGroup(geojson, group, style = null) {
        geojson.features.forEach(feature => {
            if (feature.geometry.type === "MultiPolygon") {
                feature.geometry.coordinates.forEach((coordinates) => {
                    const polygon = {type: "Polygon", coordinates: coordinates};
                    const layer = L.GeoJSON.geometryToLayer(polygon);
                    if (style) {
                        layer.setStyle(style);
                    }
                    group.addLayer(layer);
                });
            } else {
                const layer = L.GeoJSON.geometryToLayer(feature);
                if (style) {
                    layer.setStyle(style);
                }
                group.addLayer(layer);
            }
        });
    }

    _updateVueObs() {
        this.vueObs.hasFeatures = true;
        this.vueObs.numberOfFeatures = this.drawnItemsGroup.getLayers().length;
    }

    _zoomToGroup(group) {
        this.map.fitBounds(group.getBounds());
    }
}

export default Leaflet;

<template>
  <v-app-bar ref="toolbar" v-mutate="onMutate" clipped-left clipped-right app color="white">
    <v-app-bar-nav-icon @click="showTheMenu"></v-app-bar-nav-icon>
    <v-toolbar-title>
      SWIFT AOI
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <profile-menu/>
    <v-img
        contain
        max-width="100px"
        height="50px"
        :src="logo"
        alt="EOMAP Logo"
    ></v-img>
  </v-app-bar>
</template>

<script>
import ProfileMenu from "@/components/bar/CoreBarProfile";
import {mapState} from "vuex";

export default {
  name: "TheBar",
  components: {
    ProfileMenu,
  },
  props: ['logo'],
  computed: {
    ...mapState('app', ['showMenu',]),
  },
  methods: {
    showTheMenu() {
      this.$store.dispatch('app/showMenu', !this.showMenu);
    },
    onMutate () {
      let height = 0
      const toolbar = this.$refs.toolbar;
      if (toolbar) {
        height = `${toolbar.$el.offsetHeight}px`;
      }
      document.documentElement.style.setProperty('--toolbarHeight', String(height));
    }
  }
}
</script>

<style scoped>
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import MainMap from "../components/map/MainMap";
import ResultsMenu from "../components/menu/content/ResultsMenu";
import RequestsMenu from "../components/menu/content/RequestsMenu";
import ResultsOverview from "@/components/ResultsOverview";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/requests"
    },
    {
        path: "/requests",
        name: "Requests",
        components: {
            menu: RequestsMenu,
            content: MainMap
        }
    },
    {
        path: "/results",
        name: "Results",
        components: {
            menu: ResultsMenu,
            content: MainMap
        }
    },
    {
        path: '/results/:requestId',
        name: 'ResultsOverview',
        components: {
            menu: ResultsMenu,
            content: ResultsOverview
        },
        props: {
            content: true,
            menu: true
        }

    },
]

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
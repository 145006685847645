<template>
  <v-menu left open-on-hover :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          text
          class="ma-1"
          v-bind="attrs"
          v-on="on"
          v-if="$vuetify.breakpoint.lgAndUp"
      >
        {{ $t("bar.welcome") }} {{ $keycloak.tokenParsed.given_name }}
      </v-btn>
      <v-btn icon class="ma-1" v-bind="attrs" v-on="on" v-else>
        <v-icon large>mdi-account-check-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $keycloak.fullName }}</v-list-item-title>
            <v-list-item-subtitle>{{
                $keycloak.idTokenParsed.email
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-select
                attach
                prepend-icon="mdi-map"
                v-model="language"
                :items="availableLanguagesDict"
                :label="$t('bar.selectedLanguage')"
                dense
            >
            </v-select>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer/>
        <v-btn text color="accent" @click="$keycloak.logoutFn">{{
            $t("bar.logout")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ProfileMenu",
  data: () => ({
    availableLanguagesDict: [],
  }),
  computed: {
    ...mapState("management", ["ui_language", "languages_dict"]),
    language: {
      get() {
        return this.ui_language;
      },
      set(value) {
        this.updateLanguageSelection(value);
      }
    }
  },
  methods: {
    ...mapActions("management", []),
    updateLanguageSelection(selectedLanguage) {
      this.$i18n.locale = selectedLanguage;
      // this.updateUserProfileLanguage(selectedLanguage);
    }
  },
  created() {
    // this.fetchUserProfile().then(data => {
    //   this.$i18n.locale = data.ui_language;
    // });
    const availableLocales = this.$i18n.availableLocales;
    this.languages_dict.map(lang => {
      if (availableLocales.includes(lang.value)) {
        this.availableLanguagesDict.push(lang);
      }
    });
  }
};

</script>

<style scoped>

</style>


<template>
  <v-layout column class="fab-container">
    <!-- DRAW BUTTON -->
    <div class="draw-button">
      <v-speed-dial
        v-model="fab"
        direction="right"
        transition="slide-x-transition"
      >
        <template v-slot:activator>
          <v-tooltip right v-model="showDrawTooltip">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-model="fab"
                tile
                fab
                small
              >
                <v-icon v-if="fab" color="grey darken-3">mdi-close</v-icon>
                <v-icon v-else color="grey darken-3">mdi-shape-polygon-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("mapActionButtons.drawAoiTooltip") }}</span>
          </v-tooltip>
        </template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              tile
              small
              @click.stop="drawRectangle"
            >
              <v-icon color="grey darken-3">mdi-vector-rectangle</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("mapActionButtons.drawRectangleTooltip") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              tile
              small
              @click.stop="drawPolygon"
            >
              <v-icon color="grey darken-3">mdi-vector-polygon</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("mapActionButtons.drawPolygonTooltip") }}</span>
        </v-tooltip>
      </v-speed-dial>
    </div>
    <!--EDIT BUTTON-->
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="aoiSelected"
          v-on="on"
          class="edit-button"
          fab
          tile
          small
          @click.stop="editGeometry"
        >
          <v-icon color="grey darken-3">mdi-vector-polyline-edit</v-icon>
        </v-btn>
        <v-btn
          v-else
          v-on="on"
          class="edit-button"
          fab
          tile
          small
          disabled
        >
          <v-icon color="grey darken-3">mdi-vector-polyline-edit</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("mapActionButtons.editAoiTooltip") }}</span>
    </v-tooltip>
    <!--SAVE BUTTON-->
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="editDraw"
          v-on="on"
          class="save-button"
          fab
          tile
          small
          @click.stop="saveGeometry"
        >
          <v-icon color="grey darken-3">mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          v-on="on"
          class="save-button"
          fab
          tile
          small
          disabled
        >
          <v-icon color="grey darken-3">mdi-content-save-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("mapActionButtons.saveAoiTooltip") }}</span>
    </v-tooltip>
    <!--DELETE BUTTON-->
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="aoiSelected"
          v-on="on"
          fab
          tile
          small
          @click.stop="deleteGeometry"
        >
          <v-icon color="grey darken-3">mdi-delete-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          v-on="on"
          fab
          tile
          small
          disabled
        >
          <v-icon color="grey darken-3">mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("mapActionButtons.deleteAoiTooltip") }}</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "MapActionButtons",
  data: () => ({
    fab: false
  }),
  computed: {
    ...mapState("app", ["editDraw"]),
    aoiSelected() {
      return this.$leaflet.vueObs.hasFeatures && (
          this.$leaflet.vueObs.numberOfFeatures == 1 || this.$leaflet.vueObs.hasSelectedFeature
      );
    },
    showDrawTooltip (){
      return !this.fab
    }
  },
  watch: {
    editDraw: function () {
      if (this.editDraw) {
        this.$leaflet.editDrawnItems();
      } else {
        this.$leaflet.cancelEditDrawnItems();
      }
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    drawRectangle() {
      this.fab = false;
      this.disableEditMode();
      this.$leaflet.drawRectangle();
      this.showSnackbar({
        show: true,
        message: this.$t("mapActionButtons.drawRectangle"),
        timeout: 10000
      })
    },
    drawPolygon: function () {
      this.fab = false;
      this.disableEditMode();
      this.$leaflet.drawPolygon();
      this.showSnackbar({
        show: true,
        message: this.$t("mapActionButtons.drawPolygon"),
        timeout: 10000
      })
    },
    editGeometry() {
      this.$store.dispatch('app/editDraw', !this.editDraw);
      this.showSnackbar({
        show: true,
        message: this.$t("mapActionButtons.editMode"),
        timeout: 10000
      })
    },
    saveGeometry() {
      this.disableEditMode();
      this.$leaflet.saveEditedDrawnItems();
      this.showSnackbar({
        show: true,
        message: this.$t("mapActionButtons.saveChanges"),
        timeout: 1500
      })
    },
    deleteGeometry() {
      this.disableEditMode();
      this.$leaflet.clearDraw();
    },
    disableEditMode() {
      this.$store.dispatch('app/editDraw', false);
    }
  }
}
</script>


<style scoped>
.fab-container {
  position: absolute;
  top: 10px;
  left: 10px;
}

.draw-button {
  margin-bottom: 10px;
}

.edit-button {
  margin-bottom: 10px;
}

.save-button {
  margin-bottom: 10px;
}
</style>

<template>

  <v-container fluid class="ma-2">
    <v-progress-linear
        :active="!scenesLoaded"
        indeterminate
        absolute
        top
        color="primary accent-4"
    ></v-progress-linear>

    <v-row>
      <!-- Toggle Buttons for Tiles -->
      <v-col
          cols="auto"
          class="py-2 mt-1"
      >
        <v-btn-toggle
            v-model="tiles"
            density="comfortable"
        >
          <div>
            <v-btn small
                   class="mx-1"
                   value="All"
                   @click="fetchScenes('All', minElevation)">All
            </v-btn>
          </div>
          <div v-for="(tile_id, index) in this.requestTileIds" :key="index">
            <v-btn small
                   class="mx-1"
                   :value="tile_id"
                   @click="fetchScenes(tile_id, minElevation)">
              {{ tile_id }}
            </v-btn>
          </div>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>

      <v-col>
        <v-btn small @click="getTide" :disabled="tideLoading">
          <v-progress-circular indeterminate :size="20" :width="3" class="mr-2" v-if="tideLoading"></v-progress-circular>
          {{ $t("resultsOverview.getTide") }}
        </v-btn>
      </v-col>
      <!-- Filter for sun elevation angle -->
      <v-col cols="3">
        <div>
          <v-checkbox
              class="filter-checkbox"
              :label="$t('singleResultDetails.lblSunElevation')"
              v-model="filterOnOff"
              dense
          ></v-checkbox>
          <v-slider
              class="align-end filter-slider"
              color="primary"
              v-model="minElevation"
              :min="sliderMin"
              :max="sliderMax"
              hide-details
              thumb-label
              :disabled=!filterOnOff
              @change="fetchScenes(tiles, minElevation)"
          >
            <template v-slot:append>
              <v-text-field
                  class="filter-text-field"
                  v-model="minElevation"
                  prefix=">"
                  suffix="°"
                  :min="sliderMin"
                  :max="sliderMax"
                  hide-details
                  single-line
                  type="number"
                  :disabled=!filterOnOff
                  @change="fetchScenes(tiles, minElevation)"
              ></v-text-field>
            </template>
          </v-slider>
        </div>
      </v-col>

      <!-- Close Overview -->
      <v-col cols="1">
        <v-btn
            class="fixed-top"
            fab
            small
            top
            right
            icon
            @click="closeWindow"
        >
          <v-icon color="primary">mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Table -->
    <v-row>
      <v-col class="col-12 col-md-10 mx-auto">
        <v-data-table
            :headers="headers"
            :headers-length="8"
            :items="scenes"
            item-key="scene_id"
            fixed-header
            hide-default-footer
            disable-pagination
            style="overflow: auto;"
            height="70vh"
        >
          <!-- Thumbnail image -->
          <template v-slot:item.thumbnail_url="{ item }">
            <v-img
                class="ma-2 zoom-cursor"
                :src="item.thumbnail_url"
                contain
                height="100"
                width="100"
                @click="showSceneDetails(item)"
            ></v-img>
          </template>
          <!-- Quality Score -->
          <template v-slot:item.score="{ item }">
            <span>{{ calcScore(item) }}</span>
          </template>
          <!-- Scene name  -->
          <template v-slot:item.scene_id="{ item }">
            <span>
              {{ item.scene_id.replace('MSIL2A', 'MSIL1C') }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <download-btn :scene-id="item.scene_id"></download-btn>
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click.stop="copyToClipboard(item.scene_id.replace('MSIL2A', 'MSIL1C' ))"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("resultsOverview.tooltipCopy") }}</span>
              </v-tooltip>
            </span>
          </template>
          <!-- Link to scene -->
          <template v-slot:item.link="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a
                    v-bind="attrs"
                    v-on="on"
                    :href="generateEoBrwoserUrl(item)"
                    target="_blank"
                >
                  <v-icon small>mdi-open-in-new</v-icon>
                </a>
              </template>
              <span>{{ $t("resultsOverview.tooltipLink") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Pagination -->
    <v-row justify="center" v-if="hasMultiplePages">
      <v-col>
        <v-pagination
            v-model="currentPage"
            :length="pages"
            :total-visible="5"
            circle
            @input="fetchScenes(tiles, minElevation)"
        ></v-pagination>
      </v-col>
    </v-row>
    <!-- Force re-rendering of dialog with :key-->
    <!-- TODO: Better solution -->
    <v-dialog
        :attach="true"
        v-model="showSingleResultDetails"
        width="70%"
        :key="componentKey"
    >
      <single-result-details
          class="result-details"
          :scene="clickedResult"
          :scene-list="scenes"
          @close="showSingleResultDetails = false"
      >
      </single-result-details>
    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            left
            top
            fab
            small
            icon
            class="primary fixed-bottom"
            @click="exportToCSV"
        >
          <v-icon class="white--text">mdi-download</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("resultsOverview.tooltipDownload") }}</span>
    </v-tooltip>
  </v-container>
</template>


<script>
import axios from "axios";
import SingleResultDetails from "./SingleResultDetails";
import DownloadBtn from "@/components/DownloadBtn.vue";
import {mapActions} from "vuex";

export default {
  name: "ResultsOverview",
  props: ["requestId", "requestTileIds"],
  components: {
    SingleResultDetails, DownloadBtn
  },
  data: () => ({
    expanded: [],
    scenesLoaded: false,
    currentPage: 1,
    numberPages: 1,
    scenesPerPage: 20,
    numberScenes: 0,
    showSingleResultDetails: false,
    clickedResult: null,
    sentinelTiles: null,
    scenes: [],
    tiles: "All",
    filterOnOff: false,
    minElevation: "",
    componentKey: 0,
    sliderMin: 0,
    sliderMax: 90,
    tideLoading: false
  }),
  computed: {
    hasMultiplePages() {
      return (this.pages > 1) && this.scenesLoaded;
    },
    pages() {
      return this.numberPages;
      //return Math.ceil(this.numberScenes / this.scenesPerPage)
    },
    headers() {
      return [
        {
          text: this.$t("resultsOverview.rank"),
          align: 'left',
          sortable: false,
          value: 'rank',
        },
        {
          text: this.$t("resultsOverview.hdrQuicklook"),
          align: 'left',
          sortable: false,
          value: 'thumbnail_url',
        },
        {
          text: this.$t("resultsOverview.hdrScore"),
          align: 'center',
          sortable: false,
          value: 'score',
        },
        {
          text: this.$t("resultsOverview.hdrCoverage"),
          align: 'center',
          sortable: false,
          value: 'coverage',
        },
        {
          text: this.$t("resultsOverview.hdrTime"),
          align: 'left',
          sortable: false,
          value: 'datetime',
        },
        {
          text: this.$t("resultsOverview.tide"),
          align: 'left',
          sortable: false,
          value: 'tide',
        },
        {
          text: this.$t("resultsOverview.hdrLink"),
          align: 'left',
          sortable: false,
          value: 'link',
        },
        {
          text: this.$t("resultsOverview.hdrName"),
          align: 'left',
          sortable: false,
          value: 'scene_id',
        },

      ]
    },
  },
  watch: {
    '$route.params.requestId': {
      immediate: true,
      handler() {
        this.fetchScenes(this.tiles, this.minElevation)
      }
    },
    showSingleResultDetails: {
      handler() {
        this.forceRerender();
      }
    },
    tiles: {
      handler() {
        this.currentPage = 1;
      }
    },
    requestId: {
      handler() {
        this.currentPage = 1;
        this.tiles = "All";
      }
    },
    filterOnOff: {
      handler() {
        if (!this.filterOnOff) {
          this.fetchScenes(this.tiles, 0)
        } else {
          this.fetchScenes(this.tiles, this.minElevation)
        }
      }
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    async fetchScenes(tile_id, minElevation) {
      try {
        this.scenesLoaded = false
        await this.$vuetify.goTo(0)
        let url_path = `/requests/${this.requestId}/scenes_paginate?page=${this.currentPage}&size=${this.scenesPerPage}`;
        //if(tile_id == 'All' || !tile_id) {
        //url_path = `/requests/${this.requestId}/tiles/${tile_id}`
        //url_path = `/requests/${this.requestId}/scenes_paginate?page=${this.currentPage}&size=${this.scenesPerPage}`
        console.log(url_path);
        if (tile_id && tile_id != 'All') {
          url_path = url_path + `&tile_id=${tile_id}`;
          console.log(url_path);
          //url_path = `/requests/${this.requestId}/scenes_paginate?page=${this.currentPage}&size=${this.scenesPerPage}&tile_id=${tile_id}`
        }
        if(minElevation) {
          url_path = url_path + `&elevation_greater=${minElevation}`;
          console.log(url_path);
        }
        const response = await axios.get(
            url_path
            //`/requests/${this.requestId}/scenes`//?page=${this.currentPage}&size=${this.scenesPerPage}`
        );
        let data = response.data.items
        this.scenes = data;
        this.numberScenes = response.data.total;
        this.numberPages = response.data.pages;
        this.scenesLoaded = true;
        data.forEach((row, index) => {
          row.rank = (parseInt(this.currentPage) - 1) * parseInt(this.scenesPerPage) + index + 1;
        });
      } catch (err) {
        console.log(err);
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    closeWindow() {
      this.$store.commit('app/SELECTED_RESULT_ID', null);
      this.$router.push({name: 'Results'})
    },
    showSceneDetails(scene) {
      this.showSingleResultDetails = true;
      this.clickedResult = scene;
    },
    calcScore(scene) {
      return (
          scene.total_score
      ).toFixed(2)
    },
    generateEoBrwoserUrl(scene) {
      let lon, lat = null;
      const tile = scene.scene_id.split("_")[5].substring(1);
      [lon, lat] = this.getLonLatByTile(tile);
      const eoBrowserUrl = `https://apps.sentinel-hub.com/eo-browser/?zoom=9&lat=${lat}&lng=${lon}&themeId=DEFAULT-THEME&datasetId=S2L1C&fromTime=${scene.datetime.split('T')[0]}T00%3A00%3A00.000Z&toTime=${scene.datetime.split('T')[0]}T23%3A59%3A59.999Z&layerId=1_TRUE_COLOR`
      return eoBrowserUrl;
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value).then(
          () => {
            this.showSnackbar({
              show: true,
              message: this.$t("resultsOverview.msgCopy"),
              color: "success",
              timeout: 1500
            })
          }, () => {
            console.log("Error")
          });
    },
    async readSentinelTilesJson() {
      const response = await axios({method: 'get', baseURL: "/static/sentinel-2.geojson"});
      this.sentinelTiles = await response.data;
    },
    getLonLatByTile(tile) {
      if (typeof this.getLonLatByTile.cache === 'undefined') {
        this.getLonLatByTile.cache = []
      }
      if (!this.getLonLatByTile.cache[tile]) {
        const foundTile = this.sentinelTiles.features.find(item => item.properties.Name === tile);
        const coordinates = foundTile.geometry.coordinates[0][0];
        const lons = coordinates.map(c => c[0]);
        const lats = coordinates.map(c => c[1]);
        this.getLonLatByTile.cache[tile] = [
          (Math.max.apply(null, lons) + Math.min.apply(null, lons)) / 2,
          (Math.max.apply(null, lats) + Math.min.apply(null, lats)) / 2
        ];
      }
      return this.getLonLatByTile.cache[tile];
    },
    async exportToCSV() {
      const csvData = await this.convertToCSV(this.scenes.slice(0, 100));
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
      link.download = `${this.requestId}.csv`;
      link.click();
    },
    async convertToCSV(data) {
      const header = ["rank", "scene_id", "datetime", "mean_blue_color", "water_pixels", "coverage"]
      let csv = header.join(',') + '\n';
      data.forEach(row => {
        csv += header.map(header => row[header]).join(',') + '\n';
      });
      return csv;
    },
    async fetchRequest() {
      const response = await axios.get(`/requests/${this.requestId}`);
      return response.data
    },
    async fetchTide(sceneName, aoi) {
      const request = await axios.post("https://api-sdb.eomap.com/v2/missions/get-tide/", {
        aoi: aoi, scene_name: sceneName
      })

      return request.data.lat
    },
    async getTide() {
      this.tideLoading = true
      const request = await this.fetchRequest()
      const aoi = request.aoi
      const tideFetches = this.scenes.map(obj => this.fetchTide(obj.scene_id, aoi))
      const tideResults = await Promise.all(tideFetches)
      this.scenes = this.scenes.map((obj, i) => ({...obj, tide: tideResults[i]}))
      this.tideLoading = false
    }
  },

  mounted() {
    this.$store.commit('app/SELECTED_RESULT_ID', this.$route.params.requestId);
    this.readSentinelTilesJson();
  },

}
</script>

<style scoped>
.result-details {
  z-index: 1000;
}

.v-data-table /deep/ .sticky-header {
  position: sticky;
  /* height of TheBar */
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

a {
  text-decoration: none;
}

.zoom-cursor {
  cursor: zoom-in;
}

.fixed-bottom {
  position: fixed;
  bottom: 26px;
  right: 24px;
}

.fixed-top {
  position: fixed;
  top: var(--toolbarHeight) +10px;
  right: 24px;
}

.filter-checkbox {
  margin-bottom: -26px;
}

.filter-slider {
  padding-left: 26px;
}

.filter-text-field {
  width: 58px;
  padding: 4px;
  font-size: 12px;
}

</style>

// import axios from "axios";

const state = {
    userPofile: null,
    ui_language: "en",
    languages_dict: [
        {value: "de", text: "German"},
        {value: "en", text: "English"},
        {value: "it", text: "Italian"},
        {value: "pt", text: "Portuguese"},
        {value: "fr", text: "French"}
    ]
};

const actions = {
    // async fetchUserProfile({commit}) {
    //     try {
    //         const response = await axios.get(`/management/userprofile/`);
    //         commit("SET_USER_PROFILE_LANGUAGE", response.data.ui_language);
    //         return response.data;
    //     } catch (err) {
    //         console.log(err);
    //     }
    // },
    // async updateProfile({commit}, ui_language) {
    //     try {
    //         await axios.post(`/management/userprofile/`, {
    //             ui_language: ui_language
    //         });
    //         commit("SET_USER_PROFILE_LANGUAGE", ui_language);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // },
    // updateUserProfileLanguage({dispatch}, ui_language) {
    //     dispatch("updateProfile", ui_language);
    // }
};

const mutations = {
    SET_USER_PROFILE_LANGUAGE(state, ui_language) {
        state.ui_language = ui_language;
    },
    SET_USER_KEYCLOAK_PROFILE(state, profile) {
        state.userPofile = profile;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};

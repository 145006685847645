import shp from "shpjs";
import omnivore from "@mapbox/leaflet-omnivore";


class GeoFileReader {
    readFileAsync(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            if (file.name.endsWith(".zip")) {
                reader.readAsArrayBuffer(file);
            } else {
                reader.readAsText(file);
            }
        });
    }

    async processFile(file) {
        const content = await this.readFileAsync(file);
        let geoData = {
            geom: null,
            geomType: null
        };
        if (file.name.endsWith(".zip")) {
            geoData.geom = await shp(content);
            geoData.geomType = "geojson";

        } else if (file.name.endsWith(".kml")) {
            const kmlLayer = omnivore.kml.parse(content);
            geoData.geom = kmlLayer._layers;
            geoData.geomType = "layers";

        } else if (file.name.endsWith(".txt") || file.name.endsWith(".wkt")) {
            const wktLayer = omnivore.wkt.parse(content);
            geoData.geom = wktLayer._layers;
            geoData.geomType = "layers";

        } else if (file.name.endsWith(".json") || file.name.endsWith(".geojson")) {
            geoData.geom = JSON.parse(content);
            geoData.geomType = "geojson";

        } else {
            throw Error("Unsupported file type.");
        }
        return geoData;
    }

}

export default GeoFileReader;
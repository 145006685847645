<template>
  <v-dialog
      v-model="show"
      persistent
      max-width="400"
  >
    <v-card>
      <v-card-title v-if="processingStarted">
        <v-icon color="success" class="centered-icon mr-2" large> mdi-check-circle-outline</v-icon>
        {{ $t("requestSendDialog.requestStartedTitle") }}
      </v-card-title>
      <v-card-title v-else>
        <v-progress-circular
            :size="20"
            class="mr-2"
            indeterminate
            color="primary"
        ></v-progress-circular>
        {{ $t("requestSendDialog.requestNotStartedTitle") }}
      </v-card-title>

      <v-card-text v-if="processingStarted">
        {{ $t("requestSendDialog.processingStartedText") }}
      </v-card-text>

      <v-card-text v-else>
        {{ $t("requestSendDialog.processingNotStartedText") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            @click="show=false"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RequestSentDialog",
  props: [
    "isVisible",
    "processingStarted"
  ],
  computed: {
    show: {
      get() {
        return this.isVisible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
}
</script>

<style scoped>

</style>

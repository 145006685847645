import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import i18n from "@/localization/i18n";
import vuetify from "./plugins/vuetify";
import Leaflet from "./services/leaflet.service";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import tokenInterceptor from "./services/interceptors";
//export const BackendBaseUrl = import.meta.env.VITE_BACKEND_BASE_URL;

Vue.config.productionTip = false;
Vue.prototype.$leaflet = new Leaflet();
Vue.prototype.$leafletDetail = new Leaflet();
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;
// axios.defaults.baseURL = "https://swift-aoi.dev.eomap.com/";

Vue.use(VueKeyCloak, {
    init: {
        onLoad: 'login-required',
        checkLoginIframe: false
    },
    config: {
        url: 'https://auth.eomap.com/auth',
        clientId: 'swift-aoi', // swift-aoi-backend
        realm: 'eomap',
        logoutRedirectUri: window.location.origin
    },
    onReady: () => {
        tokenInterceptor();
        new Vue({
            router,
            store,
            vuetify,
            i18n,
            render: h => h(App)
        }).$mount("#app");
    },
    onInitError: error => {
        console.log(error);
    }
});

<template>
  <v-app id="inspire">
    <the-bar :logo="logo"></the-bar>
    <router-view name="menu"></router-view>
    <v-main>
      <!-- keep only the map alive -->
      <keep-alive include="MainMap">
        <router-view name="content"></router-view>
      </keep-alive>
    </v-main>
    <the-footer></the-footer>

    <v-snackbar
      v-model="snackbarDialog"
      :color="snackColor"
      :timeout="snackTimeout"
      multi-line
      outlined
    >
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackColor"
          text
          v-bind="attrs"
          @click="snackbarDialog = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import TheBar from "./components/bar/TheBar";
import TheFooter from "./components/TheFooter";
import {mapState} from "vuex";

export default {
  components: {
    TheBar,
    TheFooter
  },
  data: () => ({
    logo: require("@/assets/logo.jpg")
  }),
  computed: {
    ...mapState("app", ["snackColor", "snackMessage", "snackTimeout"]),
    snackbarDialog: {
      get() {
        return this.$store.state.app.snackDialog;
      },
      set() {
        this.$store.dispatch("app/hideSnackbar");
      }
    }
  },
  created() {
    this.$i18n.locale = 'en';
  }
}
</script>

<style>
  .centered-icon {
    vertical-align: middle
  }
</style>

<style scoped>

</style>

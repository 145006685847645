<template>
  <base-menu
      initial-menu="requests"
  >
    <v-list>

      <!--AOI-->
      <v-list-item class="mt-3">
        <v-list-item-icon>
          <v-icon large>mdi-shape-polygon-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ $t("requestMenu.aoiTitle") }}
            <v-icon v-if="readyForRequest" class="centered-icon mb-1" color="success">mdi-check-circle-outline</v-icon>
          </v-list-item-title>
          <v-list-item-action-text style="margin-top: 8px;">
            {{ $t("requestMenu.aoiText") }}
          </v-list-item-action-text>
          <v-list-item-action-text
              v-if="needsSelection"
              class="error-msg"
          >
            <v-icon class="error-icon">mdi-alert-circle-outline</v-icon>
            {{ $t("requestMenu.aoiSelectText") }}
          </v-list-item-action-text>

          <!--Upload File-->
          <v-list-item-action-text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  color="primary"
                  @click="uploadFile"
                  style="margin-top: 16px;"
                >
                  <v-icon>mdi-upload-outline</v-icon>
                  {{ $t("requestMenu.uploadFile") }}
                </v-btn>
              </template>
              <span>{{ $t("requestMenu.allowedFileTypes") }}</span>
            </v-tooltip>
            <div class="upload-file">
              <span v-if="this.selectedFile && !this.hasReaderError" class="upload-file-text">
                <v-icon v-if="fileLoaded" small class="upload-icon">mdi-file-outline</v-icon>
                <v-progress-circular
                    v-else
                    :size="20"
                    class="mr-2"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                {{ this.selectedFile.name }}
              </span>
            </div>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".zip,.shz,.wkt,.geojson,.json"
              @change="onFileChange"
            />
          </v-list-item-action-text>

          <!--Show Sentinel-2 tiles-->
          <v-list-item-action-text>
            <v-switch
              class="sentinel-switch"
              :label="$t('requestMenu.showTiles')" 
              v-model="sensorTilesEnabled" 
              dense
              :ripple="false"
              ></v-switch>
          </v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
      <!--Date Range-->
      <v-list-item>
        <v-list-item-icon>
          <v-icon large>mdi-calendar-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ $t("requestMenu.dateTitle") }}
          </v-list-item-title>
          <div class="mt-2">
            <!--Start Date-->
            <v-menu
            v-model="dateMenuStart"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                class="date-field"
                v-model="startDate"
                :label="$t('requestMenu.dateLabelStart')"
                v-bind="attrs"
                v-on="on"
                readonly
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="startDate"
              @input="dateMenuStart = false"
              :allowed-dates="disableDatesAfterEndDate"
          ></v-date-picker>
        </v-menu>
            <!--End Date-->
            <v-menu
            v-model="dateMenuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                class="date-field"
                v-model="endDate"
                :label="$t('requestMenu.dateLabelEnd')"
                v-bind="attrs"
                v-on="on"
                readonly
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="endDate"
              @input="dateMenuEnd = false"
              :allowed-dates="disableDatesPastStartDate"
          ></v-date-picker>
        </v-menu>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-row v-if="readyForRequest">
      <v-col align="center">
        <v-btn color="primary" @click="sendRequest" class="ma-4">
          {{ $t("requestMenu.requestBtn") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col align="center">
        <v-btn color="primary" class="ma-4" disabled>
          {{ $t("requestMenu.requestBtn") }}
        </v-btn>
      </v-col>
    </v-row>
    <request-sent-dialog
        :is-visible="showDialog"
        @close="dialogClosed"
        :processing-started="requestAccepted"
    ></request-sent-dialog>

  </base-menu>
</template>

<script>
import BaseMenu from "../BaseMenu.vue"
import RequestSentDialog from "../../RequestSentDialog";
import axios from "axios";
import GeoFileReader from "../../../services/geofilereader.service";

export default {
  name: "RequestsMenu",
  components: {
    BaseMenu,
    RequestSentDialog
  },
  data: () => ({
    hasReaderError: false,
    selectedFile: "",
    geoFileReader: new GeoFileReader(),
    fileLoaded: false,
    sensorTilesEnabled: false,
    startDate: new Date("2017-01-01").toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    dateMenuStart: false,
    dateMenuEnd: false,
    requestAccepted: false,
    showDialog: false,
    dialog: false,
    uploadButtonClicked: false,
  }),
  emits: [
    "data-loaded",
  ],
  computed: {
    readyForRequest() {
      return this.$leaflet.vueObs.hasFeatures && (
          this.$leaflet.vueObs.numberOfFeatures == 1 || this.$leaflet.vueObs.hasSelectedFeature
      );
    },
    needsSelection() {
      return (this.$leaflet.vueObs.numberOfFeatures > 1) && !this.$leaflet.vueObs.hasSelectedFeature;
    }
  },
  methods: {
    uploadFile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.selectedFile = files[0];
      this.fileLoaded = false;
      try {
        const geoContent = await this.geoFileReader.processFile(this.selectedFile);
        if (geoContent.geomType === "geojson") {
          this.$leaflet.addGeojsonToDrawnItems(geoContent.geom);
        } else if (geoContent.geomType === "layers") {
          this.$leaflet.addLayersToDrawnItems(geoContent.geom);
        }
        this.$leaflet.zoomToDrawnItems();
        this.$emit("data-loaded");

      } catch (err) {
        console.log(err);
        this.hasReaderError = true;
      }
      this.fileLoaded = true;
    },
    dialogClosed() {
      this.showDialog = false;
    },
    disableDatesAfterEndDate(val) {
      return val <= this.endDate;
    },
    disableDatesPastStartDate(val) {
      return val >= this.startDate;
    },
    async sendRequest() {
      this.requestAccepted = false;
      const aoi = this.$leaflet.getSelectedAoiAsGeojson();
      const data = {
        datetime: `${this.startDate}/${this.endDate}`,
        aoi: aoi["geometry"]
      };
      this.showDialog = true;

      try {
        await axios.post("/search/sentinel-2", data);
        this.requestAccepted = true;
      } catch {
        console.log("Error");
      }
    },
    onDataProcessed() {
      this.dialog = false;
    },
    toggleUpload() {
      this.uploadButtonClicked = !this.uploadButtonClicked;
    }

  },
  watch: {
    sensorTilesEnabled(newVal) {
      if (newVal) {
        this.$leaflet.showSensorTileOverlay();
      } else {
        this.$leaflet.hideSensorTileOverlay();
      }
    }
  }
}
</script>

<style scoped>
.error-msg {
  margin-top: 16px;
}

.error-icon {
  color: red;
}

.sentinel-switch {
  margin-top: 8px;
  margin-left: 5px;
  padding: 0;
}

.date-field {
  margin-right: 20px;
}

.upload-file {
  margin-top: 12px;
  margin-bottom: 10px;
}

.upload-file-text {
  font-weight: bold;
}

.upload-icon {
  color: black;
}
</style>


const state = {
  showMenu: null,
  editDraw: false,
  selectedResultId: null,
  // Snackbar
  snackDialog: false,
  snackColor: "success",
  snackMessage: "",
  snackTimeout: -1
};

const actions = {
  showMenu({commit}, value) {
    commit("SHOW_MENU", value);
  },
  editDraw({commit}, value) {
    commit("EDIT_DRAW", value);
  },
  selectedResultId({commit}, value) {
    commit("SELECTED_RESULT_ID", value)
  },
    showSnackbar({ commit }, { show, message, color, timeout }) {
    commit("SHOW_SNACKBAR", { show, message, color, timeout });
  },
  hideSnackbar({ commit }) {
    commit("HIDE_SNACKBAR");
  }
}


const mutations = {
  SHOW_MENU(state, value) {
    state.showMenu = value;
  },
  EDIT_DRAW(state, value) {
    state.editDraw = value;
  },
  SELECTED_RESULT_ID(state, value) {
    state.selectedResultId = value;
  },
    SHOW_SNACKBAR(state, { show, message, color, timeout }) {
    state.snackMessage = message;
    state.snackColor = color;
    state.snackDialog = show;
    state.snackTimeout = timeout;
  },
  HIDE_SNACKBAR(state) {
    state.snackDialog = false;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
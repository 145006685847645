<template>
  <base-menu
      initial-menu="results"
  >
    <template v-slot:additional>
      <v-btn icon @click.stop="fetchResults" class="mt-1 ">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>

    <v-layout align-center justify-center column v-if="!resultsLoaded">
      <v-flex row align-center style="margin-top: 10px;">
        <v-progress-circular
            indeterminate
            :size="40"
            color="primary"
            class="mt-6"
        ></v-progress-circular>
      </v-flex>
    </v-layout>

    <v-list v-else>
      <template v-if="filteredAoiRequests.length === 0">
        <v-layout>
          <span class="no-results-text">{{ $t("resultsMenu.noResults") }}</span>
        </v-layout>
      </template>
      <result-item
          v-for="request in filteredAoiRequests" :key="request.id"
          :request="request"
          @click.native="showRequestDetails(request.id, request.tile_ids)"
          :class="[request.id === selectedResultId ? ['accent', 'lighten-2'] : '']"
          style="cursor: pointer; border-bottom: 1px solid #e0e0e0;"
          @reload="fetchResults"
          @deleteItem="hideDeletedItem"
          @statusDone="[request.id === selectedResultId ? updateTileIds : '']"
      >
      </result-item>
    </v-list>
    <div class="text-center pa-2" v-if="hasMultiplePages">
      <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="5"
          circle
          @input="fetchResults"
      ></v-pagination>
    </div>
  </base-menu>
</template>

<script>
import BaseMenu from "../BaseMenu.vue";
import ResultItem from "./ResultItem";
import axios from "axios";
import {mapState} from 'vuex'

export default {
  name: "ResultsMenu",
  components: {
    BaseMenu,
    ResultItem
  },
  props: ["value"],
  data: () => ({
    resultsLoaded: false,
    currentPage: 1,
    resultsPerPage: 5,
    aoiRequests: [],
    responseData: [],
    numberResults: 0,
    deletedItems: []
  }),

  computed: {
    hasMultiplePages() {
      return (this.pages > 1) && this.resultsLoaded;
    },
    pages() {
      return 1;
    },
    ...mapState("app", ["selectedResultId"]),
    filteredAoiRequests(){
      return this.aoiRequests.filter(item => !this.deletedItems.includes(item.id) )
    }
  },

  methods: {
    async fetchResults() {
      try {
        this.resultsLoaded = false
        const response = await axios.get(`/requests`); //?page=${this.currentPage}&size=${this.resultsPerPage}`);
        this.aoiRequests = response.data;
        // this.numberResults = response.data.total;
        this.resultsLoaded = true
      } catch (err) {
        console.log(err);
      }
    },
    showRequestDetails(requestId, requestTileIds) {
      if (requestId === this.selectedResultId) {
        this.$router.push({name: 'Results'});
        this.$store.commit('app/SELECTED_RESULT_ID', null);
      } else {
        this.$router.push({name: 'ResultsOverview', params: {requestId: requestId, requestTileIds: requestTileIds}});
        this.$store.commit('app/SELECTED_RESULT_ID', requestId);
      }
    },
    hideDeletedItem(id){
      this.deletedItems.push(id)
    },
    updateTileIds(tile_ids){
      this.$router.push({name: 'ResultsOverview', params: {requestTileIds: tile_ids}});
    }

  },
  mounted() {
    this.fetchResults();
  },

}
</script>

<style scoped>
.no-results-text {
  margin: 20px 50px;
  text-align: center;
}
</style>
